import {useCallback, useEffect, useState} from "react";
import {ContentContainer, EmbededContainer, ICON_SUBSET} from "@riag-libs/etax-pattern-library";
import {Grunddaten} from "./sections/grunddaten";
import {initializeIcons} from "@fluentui/react/lib/Icons";
import {registerIcons, Text} from "@fluentui/react";
import {useForm} from "react-hook-form";
import {blTheme} from "@riag-libs/etax-pattern-library/lib/css/blTheme/blTheme";
import "@riag-libs/etax-pattern-library/lib/css/blTheme/blTheme.css";
import axios from "axios";
import {useBoolean} from "@fluentui/react-hooks";
import {GewinnStaatGemeinde} from "./sections/gewinnstaatgemeinde";
import {Steuerberechnung} from "./sections/steuerberechnung";
import debounce from "debounce";
import {BigUtils} from "@riag-libs/core-domain-js";
import {DateTimeFormatter, LocalDate} from "@js-joda/core";
import {Helmet} from "react-helmet";
import {openPdfInTab} from "./helpers";

initializeIcons();
registerIcons(ICON_SUBSET);

function App() {
    const {register, setValue, watch, resetField} = useForm();
    const [taxdata, setTaxdata] = useState({});
    const [results, setResults] = useState({});
    const [loading, setLoading] = useState(false);
    const [calculationValues, setCalculationValues] = useState({});
    const [showDetail, {toggle: toggleDetail}] = useBoolean(false);
    const [isAfterTax, {toggle: toggleBeforeAfter}] = useBoolean(true);

    const mainValues = watch();

    const getParameters = async () => {
        try {
            const response = await axios.get(
                "/api/initializationParameters/de_CH")
            if (response.status === 200) {
                setTaxdata(response.data);
            }
        } catch (error) {
            console.log(error, error.message);
        }
    };

    useEffect(() => {
        getParameters()
    }, []);

    const getPercentage = (x) => {
        return BigUtils.div(BigUtils.create(x), BigUtils.OneHundred);
    }

    const handleUpdate = useCallback(
        debounce(async () => {
            let regexp = new RegExp(/^\d{2}\.\d{2}\.\d{4}$/);
            const values = watch();
            const formatter = DateTimeFormatter.ofPattern('d.M.yyyy');
            const startDate = (values.beginrechnungsperiode && regexp.test(values.beginrechnungsperiode)) && LocalDate.parse(values.beginrechnungsperiode, formatter).toString();
            const endDate = (values.endrechnungsperiode && regexp.test(values.endrechnungsperiode)) && LocalDate.parse(values.endrechnungsperiode, formatter).toString();
            if (values.besteuerungsart && values.steuerjahr && values.steuergemeinde) {
                try {
                    const response = await axios.post(
                        "/api/calculate", {
                            locale: "de_CH",
                            taxYear: values.steuerjahr,
                            taxablePersonName: values.name,
                            taxMunicipalityId: values.steuergemeinde,
                            calculationMode: values.berechnungsbasis === "BEFORE_TAX" ? "BEFORE_TAX" : "AFTER_TAX",
                            taxationType: values.besteuerungsart,
                            inputType: values.berechnungsart === "ra2" ? "DETAILED" : "SIMPLE",
                            ...(values.steuerjahr && values.berechnungsart === "ra2" && {taxPeriodStart: startDate ? startDate : `${values.steuerjahr}-01-01`}),
                            ...(values.steuerjahr && values.berechnungsart === "ra2" && {taxPeriodEnd: endDate ? endDate : `${values.steuerjahr}-12-31`}),
                            ...(values.berechnungsart === "ra2" ? {
                                cantonal: {
                                    netProfitRateDetermining: values.gesamtfaktoren,
                                    netProfitRegular: values.gewinnnachordentlich,
                                    netProfitSpecial: values.sondersatz,
                                    ...((values.besteuerungsart !== "CAPITAL_COMPANY" || values.besteuerungsart !== "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && values.berechnungsbasis === "BEFORE_TAX" && {netProfitQualified: values.nettoertrag}),
                                    ...((values.besteuerungsart === "CAPITAL_COMPANY" || values.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && (values.berechnungsbasis === "AFTER_TAX" || values.berechnungsbasis === undefined) && {participationReductionRate: getPercentage(values.beteiligungsabzug)}),
                                    capitalRateDetermining: values.gesamtfaktoren2,
                                    capitalRegular: values.kapitalnachkanton,
                                    paidUpShareCapital: values.einbezahltesgrundkapital,
                                },
                                federal: {
                                    netProfitRateDetermining: values.gesamtfaktoren3,
                                    netProfitRegular: values.gewinnnachbund2,
                                    ...((values.besteuerungsart !== "CAPITAL_COMPANY" || values.besteuerungsart !== "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && values.berechnungsbasis === "BEFORE_TAX" && {netProfitQualified: values.nettoertragbeteiligungen}),
                                    ...((values.besteuerungsart === "CAPITAL_COMPANY" || values.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && (values.berechnungsbasis === "AFTER_TAX" || values.berechnungsbasis === undefined) && {participationReductionRate: getPercentage(values.beteiligungsabzug2)}),
                                }
                            } : {
                                cantonal: {
                                    netProfitRateDetermining: values.gewinnnach,
                                    netProfitRegular: values.gewinnnach,
                                    capitalRateDetermining: values.kapitalnach,
                                    capitalRegular: values.kapitalnach,
                                },
                                federal: {
                                    netProfitRateDetermining: values.gewinnnachbund,
                                    netProfitRegular: values.gewinnnachbund,
                                }
                            }),
                        })
                    if (response.status === 200) {
                        setLoading(false)
                        setResults(response.data);
                    }
                } catch (error) {
                    setLoading(false)
                    console.log(error, error.message);
                }
            }
        }, 1500),
        [setResults]
    );

    const handlePrint = useCallback(
        debounce(async () => {
            let regexp = new RegExp(/^\d{2}\.\d{2}\.\d{4}$/);
            const values = watch();
            const formatter = DateTimeFormatter.ofPattern('d.M.yyyy');
            const startDate = (values.beginrechnungsperiode && regexp.test(values.beginrechnungsperiode)) && LocalDate.parse(values.beginrechnungsperiode, formatter).toString();
            const endDate = (values.endrechnungsperiode && regexp.test(values.endrechnungsperiode)) && LocalDate.parse(values.endrechnungsperiode, formatter).toString();
            if (values.besteuerungsart && values.steuerjahr && values.steuergemeinde) {
                try {
                    const response = await axios.post(
                        "/api/print", {
                            locale: "de_CH",
                            taxYear: values.steuerjahr,
                            taxablePersonName: values.name,
                            taxMunicipalityId: values.steuergemeinde,
                            calculationMode: values.berechnungsbasis === "BEFORE_TAX" ? "BEFORE_TAX" : "AFTER_TAX",
                            taxationType: values.besteuerungsart,
                            inputType: values.berechnungsart === "ra2" ? "DETAILED" : "SIMPLE",
                            ...(values.steuerjahr && values.berechnungsart === "ra2" && {taxPeriodStart: startDate ? startDate : `${values.steuerjahr}-01-01`}),
                            ...(values.steuerjahr && values.berechnungsart === "ra2" && {taxPeriodEnd: endDate ? endDate : `${values.steuerjahr}-12-31`}),
                            ...(values.berechnungsart === "ra2" ? {
                                cantonal: {
                                    netProfitRateDetermining: values.gesamtfaktoren,
                                    netProfitRegular: values.gewinnnachordentlich,
                                    netProfitSpecial: values.sondersatz,
                                    ...((values.besteuerungsart !== "CAPITAL_COMPANY" || values.besteuerungsart !== "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && values.berechnungsbasis === "BEFORE_TAX" && {netProfitQualified: values.nettoertrag}),
                                    ...((values.besteuerungsart === "CAPITAL_COMPANY" || values.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && (values.berechnungsbasis === "AFTER_TAX" || values.berechnungsbasis === undefined) && {participationReductionRate: getPercentage(values.beteiligungsabzug)}),
                                    capitalRateDetermining: values.gesamtfaktoren2,
                                    capitalRegular: values.kapitalnachkanton,
                                    paidUpShareCapital: values.einbezahltesgrundkapital,
                                },
                                federal: {
                                    netProfitRateDetermining: values.gesamtfaktoren3,
                                    netProfitRegular: values.gewinnnachbund2,
                                    ...((values.besteuerungsart !== "CAPITAL_COMPANY" || values.besteuerungsart !== "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && values.berechnungsbasis === "BEFORE_TAX" && {netProfitQualified: values.nettoertragbeteiligungen}),
                                    ...((values.besteuerungsart === "CAPITAL_COMPANY" || values.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && (values.berechnungsbasis === "AFTER_TAX" || values.berechnungsbasis === undefined) && {participationReductionRate: getPercentage(values.beteiligungsabzug2)}),
                                }
                            } : {
                                cantonal: {
                                    netProfitRateDetermining: values.gewinnnach,
                                    netProfitRegular: values.gewinnnach,
                                    capitalRateDetermining: values.kapitalnach,
                                    capitalRegular: values.kapitalnach,
                                },
                                federal: {
                                    netProfitRateDetermining: values.gewinnnachbund,
                                    netProfitRegular: values.gewinnnachbund,
                                }
                            }),
                        }, {responseType: "blob"})
                    if (response.status === 200) {
                        openPdfInTab(response.data)
                    }
                } catch (error) {
                    console.log(error, error.message);
                }
            }
        }, 250),
        []
    );

    return (
        <EmbededContainer appTheme={blTheme}>
            <Helmet>
                <script
                    src="/iframeSizer.contentWindow.min.js"
                    type="text/javascript"
                />
            </Helmet>
            <ContentContainer className={"vertical"}>
                <Grunddaten taxdata={taxdata} handleUpdate={handleUpdate} register={register}
                            setValue={setValue} setLoading={setLoading}
                            isAfterTax={isAfterTax} toggleBeforeAfter={toggleBeforeAfter}
                            showDetail={showDetail} toggleDetail={toggleDetail} mainValues={mainValues}
                            setCalculationValues={setCalculationValues}
                />
                <GewinnStaatGemeinde handleUpdate={handleUpdate} register={register} setValue={setValue}
                                     showDetail={showDetail} toggleDetail={toggleDetail}
                                     setLoading={setLoading}
                                     isAfterTax={isAfterTax} mainValues={mainValues}
                                     calculationValues={calculationValues}
                                     setCalculationValues={setCalculationValues}
                                     resetField={resetField}
                />
                <Steuerberechnung results={results} loading={loading} handlePrint={handlePrint}/>
                <ContentContainer className='l-m-tb-16'>
                    <Text as='p' className="t-standard">Diese Berechnung ist unverbindlich und erfolgt
                        ohne Gewähr. Die effektive Steuerveranlagung kann davon abweichen.</Text>
                </ContentContainer>
            </ContentContainer>
        </EmbededContainer>
    );
}

export default App;
