import {ContentContainer, Dropdown, FieldSet, RadioButton, TextField} from "@riag-libs/etax-pattern-library";
import {useEffect} from "react";

export const Grunddaten = (props) => {
    const {
        register,
        setValue,
        handleUpdate,
        isAfterTax,
        toggleBeforeAfter,
        taxdata,
        showDetail,
        toggleDetail,
        setLoading,
        mainValues,
        setCalculationValues
    } = props

    useEffect(() => {
        register("steuerjahr");
        register("steuergemeinde");
        register("besteuerungsart");
        register("beginrechnungsperiode");
        register("endrechnungsperiode");
        register("berechnungsart")
        register("berechnungsbasis")
    }, [register]);

    const newMunicipalities =
        taxdata[0]?.taxMunicipalities.map((rec) => {
            return {
                key: rec.key,
                text: rec.value,
            };
        });

    const newTaxationtypes =
        taxdata[0]?.taxationTypes.map((rec) => {
            return {
                key: rec.key,
                text: rec.value,
            };
        });

    const newTaxationYears =
        taxdata[0]?.taxYears.map((rec) => {
            return {
                key: rec,
                text: rec,
            };
        });

    function checkFormat(input) {
        var format = /^\d{2}\.\d{2}\.\d{4}$/;
        if (!input.match(format)) {
            return "Bitte geben Sie Ihr Datum im Format: tt.mm.jjjj";
        }
        return false;
    }

    return (
        <FieldSet title="Grunddaten" headingTag='h2' headingTagClass='title2'>
            <ContentContainer className='l-flex-align-end-l'>
                <Dropdown label='Steuerjahr' size='l-w-165 l-fb-16'
                          options={newTaxationYears && newTaxationYears.reverse()}
                          onChange={(e, selection) => {
                              setValue("steuerjahr", selection.key);
                              handleUpdate();
                              setLoading(true)
                          }}
                />
                <Dropdown label='Besteuerungsart' size='l-w-495 l-fb-50'
                          onChange={(e, selection) => {
                              setValue("besteuerungsart", selection.key);
                              handleUpdate()
                          }}
                          options={newTaxationtypes}/>
                <Dropdown label='Steuergemeinde' size='l-w-330 l-fb-33'
                          onChange={(e, selection) => {
                              setValue("steuergemeinde", selection.key);
                              handleUpdate()
                          }}
                          options={newMunicipalities}/>
            </ContentContainer>
            <ContentContainer className='l-flex-align-end-l'>
                <RadioButton label='Berechnungsart' size='l-w-330 l-fb-33'
                             options={[{key: 'ra1', text: 'Einfache Berechnung',}, {
                                 key: 'ra2',
                                 text: 'Detaillierte Berechnung',
                             }]}
                             defaultSelectedKey={showDetail ? 'ra2' : 'ra1'}
                             onChange={(e, selection) => {
                                 setValue("berechnungsart", selection.key);
                                 toggleDetail();
                                 setLoading(true)
                                 setCalculationValues({})
                                 handleUpdate()
                             }}/>
                <RadioButton label='Berechnungsbasis' size='l-w-330 l-fb-33'
                             options={[{key: 'BEFORE_TAX', text: 'Gewinn vor Steuern',}, {
                                 key: 'AFTER_TAX',
                                 text: 'Gewinn nach Steuern',
                             },]}
                             defaultSelectedKey={isAfterTax ? 'AFTER_TAX' : 'BEFORE_TAX'}
                             onChange={(e, selection) => {
                                 setValue("berechnungsbasis", selection.key);
                                 toggleBeforeAfter();
                                 handleUpdate()
                                 setLoading(true)
                             }}/>
                <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/>
            </ContentContainer>
            {showDetail ?
                <ContentContainer className='l-flex-align-end-l' style={{alignItems: "flex-start"}}>
                    <TextField label='Beginn Rechnungsperiode (tt.mm.jjjj)'
                               defaultValue={mainValues.steuerjahr ? `01.01.${mainValues.steuerjahr}` : null}
                               size='l-w-330 l-fb-33'
                               inputMode={"numeric"}
                               errorMessage={mainValues.beginrechnungsperiode && checkFormat(mainValues.beginrechnungsperiode)}
                               onChange={e => {
                                   setValue("beginrechnungsperiode", e.target.value.replaceAll("_", ""));
                                   handleUpdate()
                               }}
                    />
                    <TextField label='Ende Rechnungsperiode (tt.mm.jjjj)'
                               defaultValue={mainValues.steuerjahr ? `31.12.${mainValues.steuerjahr}` : null}
                               size='l-w-330 l-fb-33'
                               errorMessage={mainValues.endrechnungsperiode && checkFormat(mainValues.endrechnungsperiode)}
                               inputMode={"numeric"}
                               onChange={e => {
                                   setValue("endrechnungsperiode", e.target.value.replaceAll("_", ""));
                                   handleUpdate()
                               }}/>
                    <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/>
                </ContentContainer>
                : ''
            }
        </FieldSet>
    );
}

