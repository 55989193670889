import {BigUtils} from "@riag-libs/core-domain-js";
import Big from "big.js";

var _ = require('lodash');

export const format = (value, format, lng) => {
    if (format === 'currency')
        return _.isNull(value) ? '' : new Intl.NumberFormat(lng, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value)
    if (format === 'percent')
        return _.isNumber(value) ? new Intl.NumberFormat(lng, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value) : ''
    if (format === 'numberPercent')
        return _.isNumber(value) ? new Intl.NumberFormat(lng, {
            style: 'percent',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(BigUtils.div(new Big(value), BigUtils.OneHundred)) : ''
    if (_.isNumber(value))
        return new Intl.NumberFormat(lng).format(value)
    return value;
}

export const formatNonNull = (value, type, lng) => {
    if (value) {
        return format(value, type, lng)
    }
    return ""
}

export const openPdfInTab = (blob) => {
    const blob2 = new Blob([blob], {type: "application/pdf"});
    const url = window.URL.createObjectURL(blob2);
    window.open(url, "_blank");
}