import {ContentContainer, FieldSet, IconButtonWithCallout, TextField} from "@riag-libs/etax-pattern-library";
import {useEffect} from "react";
import {BigUtils} from "@riag-libs/core-domain-js";

export const GewinnStaatGemeinde = (props) => {
    const {
        register,
        setValue,
        handleUpdate,
        showDetail,
        isAfterTax,
        mainValues,
        setLoading,
        setCalculationValues,
        calculationValues,
        resetField
    } = props

    useEffect(() => {
        register("gewinnnach");
        register("gewinnvor");
        register("kapitalnach");
        register("kapitalvor");
        register("gewinnnachbund");
        register("gewinnvorbund");
        register("gesamtfaktoren");
        register("gewinnnachsteuern");
        register("gewinnvorsteuern");
        register("gewinnnachordentlich");
        register("gewinnvorordentlich");
        register("sondersatz");
        register("beteiligungsabzug");
        register("nettoertrag");
        register("gesamtfaktoren2");
        register("kapitalnachkanton");
        register("kapitalvorkanton");
        register("einbezahltesgrundkapital");
        register("gesamtfaktoren3");
        register("gewinnnachbund2");
        register("gewinnvorbund2");
        register("beteiligungsabzug2");
        register("nettoertragbeteiligungen");
    }, [register]);

    useEffect(() => {
        if (mainValues.gewinnnach && mainValues.berechnungsart === "ra2") {
            setCalculationValues({
                ...calculationValues,
                gewinnnachordentlich: mainValues.gewinnnach,
            })
        }
    }, [mainValues.berechnungsart]);

    useEffect(() => {
        resetField("sondersatz")
        resetField("einbezahltesgrundkapital")
        resetField("nettoertrag")
        resetField("beteiligungsabzug")
        resetField("beteiligungsabzug2")
        resetField("nettoertragbeteiligungen")
        setCalculationValues({
            ...calculationValues,
            sondersatz: "",
        })
    }, [mainValues.besteuerungsart]);

    const calculate = () => {
        return BigUtils.add(BigUtils.create(calculationValues.sondersatz), BigUtils.create(calculationValues.gewinnnachordentlich));
    }

    const KontextInfoSondersatz =
        IconButtonWithCallout({
            iconButtonAriaLabel: 'KontextInformation zu Steuerbarer Reingewinn Sondersatz',
            calloutTitle: 'Steuerbarer Reingewinn Sondersatz',
            calloutTitleHeading: 'h3',
            calloutText: "Mit der Berechnungsbasis 'Gewinn vor Steuern' wird die Steuer auf den stillen Reserven dem ordentlichen Gewinn belastet."
        })

    return (
        <>
            {!showDetail ? (
                    <FieldSet title="Gewinn & Kapital" headingTag='h2' headingTagClass='title2'>
                        <ContentContainer className='l-flex-align-end-l'>
                            <TextField label={isAfterTax ? 'Gewinn nach Steuern Kanton' : 'Gewinn vor Steuern Kanton'}
                                       defaultValue={mainValues.gewinnnach ? mainValues.gewinnnach : undefined}
                                       onChange={e => {
                                           setValue("gewinnnach", e.target.value);
                                           setValue("gewinnnachordentlich", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                                       size='l-w-330 l-fb-33'
                            />
                            <TextField label={isAfterTax ? 'Kapital nach Steuern' : 'Kapital vor Steuern'}
                                       size='l-w-330 l-fb-33'
                                       defaultValue={mainValues.kapitalnach ? mainValues.kapitalnach : undefined}
                                       onChange={e => {
                                           setValue("kapitalnach", e.target.value);
                                           setValue("kapitalnachkanton", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                            <TextField label={isAfterTax ? 'Gewinn nach Steuern Bund' : 'Gewinn vor Steuern Bund'}
                                       size='l-w-330 l-fb-33'
                                       defaultValue={mainValues.gewinnnachbund ? mainValues.gewinnnachbund : undefined}
                                       onChange={e => {
                                           setValue("gewinnnachbund", e.target.value);
                                           setValue("gewinnnachbund2", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                        </ContentContainer>
                    </FieldSet>
                )
                :
                <>
                    <FieldSet title="Gewinn Staats- und Gemeindesteuern" headingTag='h2' headingTagClass='title2'>
                        <ContentContainer className='l-flex-align-end-l'>
                            <TextField label='Gesamtfaktoren' size='l-w-330 l-fb-33'
                                       value={mainValues.gesamtfaktoren ? mainValues.gesamtfaktoren : undefined}
                                       onChange={e => {
                                           setValue("gesamtfaktoren", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                            {(mainValues.besteuerungsart === "CAPITAL_COMPANY" || mainValues.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") ? (
                                <TextField
                                    label={isAfterTax ? 'Gewinn nach Steuern Kanton' : 'Gewinn vor Steuern Kanton'}
                                    size='l-w-330 l-fb-33'
                                    readOnly={true}
                                    value={calculate()}
                                    onChange={e => {
                                        setValue(isAfterTax ? "gewinnnachsteuern" : "gewinnvorsteuern", e.target.value);
                                        setLoading(true)
                                    }}
                                />
                            ) : (
                                <TextField
                                    label={isAfterTax ? 'Gewinn nach Steuern Kanton' : 'Gewinn vor Steuern Kanton'}
                                    className='l-w-330 l-fb-50'
                                    value={mainValues.gewinnnach ? mainValues.gewinnnach : undefined}
                                    onChange={e => {
                                        setValue("gewinnnachordentlich", e.target.value);
                                        setValue("gewinnnach", e.target.value);
                                        handleUpdate()
                                        setLoading(true)
                                        setCalculationValues({
                                            ...calculationValues,
                                            gewinnnachordentlich: e.target.value,
                                        })
                                    }}
                                />
                            )}
                            <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/>
                        </ContentContainer>
                        <ContentContainer className='l-flex-align-end-l'>
                            {(mainValues.besteuerungsart === "CAPITAL_COMPANY" || mainValues.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && (
                                <TextField
                                    label={isAfterTax ? 'Gewinn nach Steuern Kanton ordentlich' : 'Gewinn vor Steuern Kanton ordentlich'}
                                    size='l-w-330 l-fb-33'
                                    defaultValue={mainValues.gewinnnach ? mainValues.gewinnnach : undefined}
                                    onChange={e => {
                                        setValue("gewinnnachordentlich", e.target.value);
                                        setValue("gewinnnach", e.target.value);
                                        handleUpdate()
                                        setLoading(true)
                                        setCalculationValues({
                                            ...calculationValues,
                                            gewinnnachordentlich: e.target.value,
                                        })
                                    }}
                                />
                            )}
                            {(mainValues.besteuerungsart === "CAPITAL_COMPANY" || mainValues.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") && (
                                <TextField label='Steuerbarer Reingewinn Sondersatz'
                                           size='l-w-330-outside-p l-fb-33-outside-p'
                                           outSideChild={KontextInfoSondersatz}
                                           onChange={e => {
                                               setValue("sondersatz", e.target.value);
                                               handleUpdate()
                                               setLoading(true)
                                               setCalculationValues({
                                                   ...calculationValues,
                                                   sondersatz: e.target.value,
                                               })
                                           }}
                                />
                            )}
                            <ContentContainer size='l-w-330-outside-m l-fb-33-outside-m' className='horizontal'/>
                        </ContentContainer>
                        {(mainValues.besteuerungsart === "CAPITAL_COMPANY" || mainValues.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") &&
                            <ContentContainer className='l-flex-align-end-l'>
                                {isAfterTax ?
                                    <TextField label='Beteiligungsabzug in %' size='l-w-330 l-fb-33'
                                               onChange={e => {
                                                   setValue("beteiligungsabzug", e.target.value);
                                                   setValue("nettoertrag", e.target.value);
                                                   handleUpdate()
                                                   setLoading(true)
                                               }}
                                    />
                                    :
                                    <TextField label='Nettoertrag qualifizierte Beteiligungen'
                                               size='l-w-330 l-fb-33'
                                               onChange={e => {
                                                   setValue("nettoertrag", e.target.value);
                                                   setValue("beteiligungsabzug", e.target.value);
                                                   handleUpdate()
                                                   setLoading(true)
                                                   setCalculationValues({
                                                       ...calculationValues,
                                                       beteiligungsabzug: e.target.value,
                                                   })
                                               }}
                                    />
                                }
                                <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/> <ContentContainer
                                size='l-w-330 l-fb-33' className='horizontal'/>
                            </ContentContainer>}
                        {(showDetail && isAfterTax) ?
                            <div className="visuallyHidden" aria-live="polite">
                                Feld Beteiligungsabzug in % wird angezeigt.
                            </div> : ''
                        }
                        {(showDetail && !isAfterTax) ?
                            <div className="visuallyHidden" aria-live="polite">
                                Feld Nettoertrag qualifizierte Beteiligungen wird angezeigt.
                            </div> : ''
                        }
                    </FieldSet>
                    <FieldSet title="Kapital Staats- und Gemeindesteuern" headingTag='h2' headingTagClass='title2'>
                        <ContentContainer className='l-flex-align-end-l'>
                            <TextField label='Gesamtfaktoren' size='l-w-330 l-fb-33'
                                       value={mainValues.gesamtfaktoren2 ? mainValues.gesamtfaktoren2 : undefined}
                                       onChange={e => {
                                           setValue("gesamtfaktoren2", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                            <TextField label={isAfterTax ? 'Kapital nach Steuern Kanton' : 'Kapital vor Steuern Kanton'}
                                       size='l-w-330 l-fb-33'
                                       defaultValue={mainValues.kapitalnach ? mainValues.kapitalnach : undefined}
                                       onChange={e => {
                                           setValue("kapitalnach", e.target.value);
                                           setValue("kapitalnachkanton", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                            {(mainValues.besteuerungsart === "CAPITAL_COMPANY" || mainValues.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") ?
                                <TextField label='Einbezahltes Grundkapital' size='l-w-330 l-fb-33'
                                           onChange={e => {
                                               setValue("einbezahltesgrundkapital", e.target.value);
                                               handleUpdate()
                                               setLoading(true)
                                           }}
                                /> : <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/>}
                        </ContentContainer>
                    </FieldSet>
                    <FieldSet title="Gewinn direkte Bundessteuern" headingTag='h2' headingTagClass='title2'>
                        <ContentContainer className='l-flex-align-end-l'>
                            <TextField label='Gesamtfaktoren' size='l-w-330 l-fb-33'
                                       value={mainValues.gesamtfaktoren3 ? mainValues.gesamtfaktoren3 : undefined}
                                       onChange={e => {
                                           setValue("gesamtfaktoren3", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                            <TextField label={isAfterTax ? 'Gewinn nach Steuern Bund' : 'Gewinn vor Steuern Bund'}
                                       size='l-w-330 l-fb-33'
                                       defaultValue={mainValues.gewinnnachbund ? mainValues.gewinnnachbund : undefined}
                                       onChange={e => {
                                           setValue("gewinnnachbund", e.target.value);
                                           setValue("gewinnnachbund2", e.target.value);
                                           handleUpdate()
                                           setLoading(true)
                                       }}
                            />
                            <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/>
                        </ContentContainer>
                        {(mainValues.besteuerungsart === "CAPITAL_COMPANY" || mainValues.besteuerungsart === "CAPITAL_COMPANY_NONPROFIT_PURPOSE") &&
                            <ContentContainer className='l-flex-align-end-l'>
                                {isAfterTax ?
                                    <TextField label='Beteiligungsabzug in %' size='l-w-330 l-fb-33'
                                               defaultValue={mainValues.beteiligungsabzug2 ? mainValues.beteiligungsabzug2 : undefined}
                                               onChange={e => {
                                                   setValue("beteiligungsabzug2", e.target.value);
                                                   setValue("nettoertragbeteiligungen", e.target.value);
                                                   handleUpdate()
                                                   setLoading(true)
                                               }}
                                    />
                                    :
                                    <TextField label='Nettoertrag qualifizierte Beteiligungen'
                                               size='l-w-330 l-fb-33'
                                               defaultValue={mainValues.nettoertragbeteiligungen ? mainValues.nettoertragbeteiligungen : undefined}
                                               onChange={e => {
                                                   setValue("nettoertragbeteiligungen", e.target.value);
                                                   setValue("beteiligungsabzug2", e.target.value);
                                                   handleUpdate()
                                                   setLoading(true)
                                               }}
                                    />
                                }
                                <ContentContainer size='l-w-330 l-fb-33' className='horizontal'/> <ContentContainer
                                size='l-w-330 l-fb-33' className='horizontal'/>
                            </ContentContainer>}
                        {(showDetail && isAfterTax) ?
                            <div className="visuallyHidden" aria-live="polite">
                                Feld Beteiligungsabzug in % wird angezeigt.
                            </div> : ''
                        }
                        {(showDetail && !isAfterTax) ?
                            <div className="visuallyHidden" aria-live="polite">
                                Feld Nettoertrag qualifizierte Beteiligungen wird angezeigt.
                            </div> : ''
                        }
                    </FieldSet>
                </>
            }</>

    );
}

