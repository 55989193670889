import {
    ContentContainer,
    Expander,
    ICON_PROPS,
    ObjectItem,
    ObjectList,
    ObjectListHeader
} from "@riag-libs/etax-pattern-library";
import {DefaultButton, Shimmer, ShimmerElementType, Text} from "@fluentui/react";
import {formatNonNull} from "../helpers";

export const Steuerberechnung = (props) => {
    const {results, loading, handlePrint} = props

    const TaxCalculationStaatHeader =
        <ObjectListHeader>
            <Text as='span' className="flexItem title3 l-fb-40">Berechnung Staatssteuer</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">steuerbar</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">satzbestimmend</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuersatz</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuerbetrag</Text>
        </ObjectListHeader>

    const TaxCalculationGemeindeHeader =
        <ObjectListHeader>
            <Text as='span' className="flexItem title3 l-fb-40">Berechnung Gemeindesteuer</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">steuerbar</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">satzbestimmend</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuersatz</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuerbetrag</Text>
        </ObjectListHeader>

    const TaxCalculationGemeindeHeader2023 =
        <ObjectListHeader>
            <Text as='span' className="flexItem title3 l-fb-40">Berechnung Gemeindesteuer</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Basis</Text>
            <div className="flexItem t-listHeading t-right l-fb-15"/>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuersatz</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuerbetrag</Text>
        </ObjectListHeader>

    const TaxCalculationKantonSummaryHeader =
        <ObjectListHeader>
            <Text as='span' className="flexItem title3 l-fb-40">Zusammenfassung Steuerberechnung</Text>
            <div className="flexItem t-listHeading t-right l-fb-15"/>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Gewinnsteuer</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Kapitalsteuer</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuerbetrag</Text>
        </ObjectListHeader>

    const TaxCalculationKantonChurchHeader =
        <ObjectListHeader>
            <Text as='span' className="flexItem title3 l-fb-40">Berechnung Kirchensteuer</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Basis</Text>
            <div className="flexItem t-listHeading t-right l-fb-15"/>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuersatz</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuerbetrag</Text>
        </ObjectListHeader>

    const TaxCalculationBundHeader =
        <ObjectListHeader>
            <Text as='span' className="flexItem title3 l-fb-40">Steuerberechnung</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">steuerbar</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">satzbestimmend</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Steuersatz</Text>
            <Text as='span' className="flexItem t-listHeading t-right l-fb-15">Einfache Steuer</Text>
        </ObjectListHeader>

    if (loading) {
        return (
            <ObjectList title='Steuerberechnung' headingTag='h2' headingTagClass='title2' className='expanderList'
                        buttonList={<DefaultButton
                            text='PDF ausdrucken'
                            iconProps={ICON_PROPS.PrintIcon}
                            onClick={() => handlePrint()}
                        />}
            >
                <Shimmer
                    shimmerElements={[
                        {type: ShimmerElementType.line, height: 69, width: "100%"},
                    ]}
                    className={{marginBottom: "1px"}}
                />
                <Shimmer
                    shimmerElements={[
                        {type: ShimmerElementType.line, height: 69, width: "100%"},
                    ]}
                />
                <ObjectItem className='totalRow'>
                    <ContentContainer className='horizontal'>
                        <Text as='span' className="flexItem title3">Total Steuer</Text>
                        {!loading && <Text as='span'
                                           className="flexItem title3 t-right">{formatNonNull(results.totalTax, 'currency', "de-CH")}</Text>}
                    </ContentContainer>
                </ObjectItem>
            </ObjectList>
        )
    }

    const renderCantonContent = results?.cantonal?.cantonalTax?.details.map((el) => (
        <ObjectItem>
            <ContentContainer className='l-flex-rowgap-8'>
                <ContentContainer size='l-fb-40' className='horizontal t-ellipsis'>
                    <Text as='span'
                          className="flexItem t-listText title4-s">{el.description}</Text>
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.taxable &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">steuerbar:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.taxable, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rateDetermining &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">satzbestimmend:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.rateDetermining, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rate &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Steuersatz:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(Number(el.rate), 'percent', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.tax &&
                        <>
                            <Text as='span' className="flexItem t-listText hiddenLabel">Einfache
                                Steuer:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.tax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
            </ContentContainer>
        </ObjectItem>
    ))

    const renderGemeinde2023 = results?.cantonal?.municipalityTax?.details.map((el) => (
        <ObjectItem>
            <ContentContainer className='l-flex-rowgap-8'>
                <ContentContainer size='l-fb-40' className='horizontal t-ellipsis'>
                    <Text as='span'
                          className="flexItem t-listText title4-s">{el.description}</Text>
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.base &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Basis:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.base, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'/>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rate &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Steuersatz:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(Number(el.rate), 'percent', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.tax &&
                        <>
                            <Text as='span' className="flexItem t-listText hiddenLabel">Einfache
                                Steuer:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.tax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
            </ContentContainer>
        </ObjectItem>
    ))

    const renderGemeinde = results?.cantonal?.municipalityTax?.details.map((el) => (
        <ObjectItem>
            <ContentContainer className='l-flex-rowgap-8'>
                <ContentContainer size='l-fb-40' className='horizontal t-ellipsis'>
                    <Text as='span'
                          className="flexItem t-listText title4-s">{el.description}</Text>
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.taxable &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">steuerbar:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.taxable, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rateDetermining &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">satzbestimmend:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.rateDetermining, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rate &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Steuersatz:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(Number(el.rate), 'percent', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.tax &&
                        <>
                            <Text as='span' className="flexItem t-listText hiddenLabel">Einfache
                                Steuer:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.tax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
            </ContentContainer>
        </ObjectItem>
    ))

    const renderCantonSummary = results?.cantonal?.summary?.summaryDetails.map((el) => (
        <ObjectItem>
            <ContentContainer className='l-flex-rowgap-8'>
                <ContentContainer size='l-fb-40' className='horizontal t-ellipsis'>
                    <Text as='span'
                          className="flexItem t-listText title4-s">{el.description}</Text>
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'/>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.profitTax &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Gewinnsteuer:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.profitTax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.capitalTax &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Kapitalsteuer:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.capitalTax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.totalTax &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Steuerbetrag:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.totalTax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
            </ContentContainer>
        </ObjectItem>
    ))

    const renderBundesSummary = results?.federal?.details.map((el) => (
        <ObjectItem>
            <ContentContainer className='l-flex-rowgap-8'>
                <ContentContainer size='l-fb-40' className='horizontal t-ellipsis'>
                    <Text as='span'
                          className="flexItem t-listText title4-s">{el.description}</Text>
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.taxable &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">steuerbar:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.taxable, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rateDetermining &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">satzbestimmend:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.rateDetermining, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.rate &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Steuersatz:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(Number(el.rate), 'percent', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
                <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                    {el.tax &&
                        <>
                            <Text as='span'
                                  className="flexItem t-listText hiddenLabel">Steuerbetrag:</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{formatNonNull(el.tax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
            </ContentContainer>
        </ObjectItem>
    ))

    const totalCantonContent = () => {
        const total = [(
            <ObjectItem className='totalRow'>
                <ContentContainer className='horizontal'>
                    <Text as='span' className="flexItem t-listText">Total Staatssteuer</Text>
                    <Text as='span'
                          className="flexItem t-listText t-right">{results?.cantonal?.cantonalTax?.totalTax && formatNonNull(results.cantonal.cantonalTax.totalTax, 'currency', "de-CH")}</Text>
                </ContentContainer>
            </ObjectItem>
        )]
        return (
            <ObjectList title="Berechnung Staatssteuer" headingTag='h4'
                        headingTagClass="title3 responsiveHidden"
                        listHeader={TaxCalculationStaatHeader} className='tableView'>
                {renderCantonContent && renderCantonContent.concat(total)}
            </ObjectList>
        )
    }

    const totalGemeindeContent = () => {
        const total = [(
            <ObjectItem className='totalRow'>
                <ContentContainer className='horizontal'>
                    {results?.cantonal?.municipalityTax?.totalTax &&
                        <>
                            <Text as='span' className="flexItem t-listText">Total Gemeindesteuer</Text>
                            <Text as='span'
                                  className="flexItem t-listText t-right">{results?.cantonal?.municipalityTax?.totalTax && formatNonNull(results.cantonal.municipalityTax.totalTax, 'currency', "de-CH")}</Text>
                        </>
                    }
                </ContentContainer>
            </ObjectItem>
        )]
        return (
            <ObjectList title="Berechnung Gemeindesteuer" headingTag='h4'
                        headingTagClass="title3 responsiveHidden"
                        listHeader={results.format === "2023" ? TaxCalculationGemeindeHeader2023 : TaxCalculationGemeindeHeader}
                        className='tableView'>
                {results.format === "2023" ? renderGemeinde2023 && renderGemeinde2023.concat(total) : renderGemeinde && renderGemeinde.concat(total)}
            </ObjectList>
        )
    }

    const totalCantonSummary = () => {
        const total = [(
            <ObjectItem className='totalRow'>
                <ContentContainer className='horizontal'>
                    <Text as='span' className="flexItem title4">Total Staats-, Gemeinde- und
                        Kirchensteuern</Text>
                    <Text as='span'
                          className="flexItem title4 t-right">{results?.cantonal?.summary?.totalTax && formatNonNull(results.cantonal.summary.totalTax, 'currency', "de-CH")}</Text>
                </ContentContainer>
            </ObjectItem>
        )]
        return (
            <ObjectList title="Zusammenfassung Steuerberechnung" headingTag='h4'
                        headingTagClass="title3 responsiveHidden"
                        listHeader={TaxCalculationKantonSummaryHeader} className='tableView'>
                {renderCantonSummary && renderCantonSummary.concat(total)}
            </ObjectList>
        )
    }

    const totalBundesSummary = () => {
        const total = [(
            <ObjectItem className='totalRow'>
                <ContentContainer className='horizontal'>
                    <Text as='span' className="flexItem title4">Total Direkte Bundessteuer</Text>
                    <Text as='span'
                          className="flexItem title4 t-right">{results.federal?.totalTax && formatNonNull(results.federal.totalTax, 'currency', "de-CH")}</Text>
                </ContentContainer>
            </ObjectItem>
        )]
        return (
            <ObjectList title="Steuerberechnung" headingTag='h4' headingTagClass="title3 responsiveHidden"
                        listHeader={TaxCalculationBundHeader} className='tableView'>
                {renderBundesSummary && renderBundesSummary.concat(total)}
            </ObjectList>
        )
    }

    return (
        <ObjectList title='Steuerberechnung' headingTag='h2' headingTagClass='title2' className='expanderList'
                    buttonList={<DefaultButton
                        text='PDF ausdrucken'
                        iconProps={ICON_PROPS.PrintIcon}
                        onClick={() => handlePrint()}
                    />}
        >
            <Expander title='Staats- und Gemeindesteuern' headerTitleTag='h3'
                      headerTitleTagClass='t-listTextBig'
                      headerTitleSize='l-fb-70'
                      headerContent={<ContentContainer className='horizontal l-flex-justify-end'>
                          <Text as='span' className="flexItem t-listTextBig hiddenLabel">Total:</Text>
                          <Text as='span'
                                className="flexItem t-listTextBig t-right">{results?.cantonal?.summary?.totalTax && formatNonNull(results.cantonal.summary.totalTax, 'currency', "de-CH")}</Text>
                      </ContentContainer>}
                      headerContentSize='l-fb-30 l-flex-justify-end'>
                {totalCantonContent()}
                {totalGemeindeContent()}
                <ObjectList title="Berechnung Kirchensteuer" headingTag='h4'
                            headingTagClass="title3 responsiveHidden"
                            listHeader={TaxCalculationKantonChurchHeader} className='tableView'>
                    <ObjectItem className={"totalRow"}>
                        <ContentContainer className='l-flex-rowgap-8'>
                            <ContentContainer size='l-fb-40' className='horizontal t-ellipsis'>
                                <Text as='span'
                                      className="flexItem t-listText title4-s">{results?.cantonal?.churchTax?.description}</Text>
                            </ContentContainer>
                            <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                                {results?.cantonal?.churchTax?.base &&
                                    <>
                                        <Text as='span'
                                              className="flexItem t-listText hiddenLabel">Basis:</Text>
                                        <Text as='span'
                                              className="flexItem t-listText t-right">{results?.cantonal?.churchTax?.base && formatNonNull(results.cantonal.churchTax.base, 'currency', "de-CH")}</Text>
                                    </>
                                }
                            </ContentContainer>
                            <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'/>
                            <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                                {results?.cantonal?.churchTax?.rate &&
                                    <>
                                        <Text as='span'
                                              className="flexItem t-listText hiddenLabel">Steuerfuss:</Text>
                                        <Text as='span'
                                              className="flexItem t-listText t-right">{results?.cantonal?.churchTax?.rate && formatNonNull(Number(results.cantonal.churchTax.rate), 'percent', "de-CH")}</Text>
                                    </>
                                }
                            </ContentContainer>
                            <ContentContainer size='l-fb-15' className='horizontal t-ellipsis'>
                                {results?.cantonal?.churchTax?.tax &&
                                    <>
                                        <Text as='span'
                                              className="flexItem t-listText hiddenLabel">Steuerbetrag:</Text>
                                        <Text as='span'
                                              className="flexItem t-listText t-right">{results?.cantonal?.churchTax?.tax && formatNonNull(results.cantonal.churchTax.tax, 'currency', "de-CH")}</Text>
                                    </>
                                }
                            </ContentContainer>
                        </ContentContainer>
                    </ObjectItem>
                    {/*<ObjectItem className='totalRow'>*/}
                    {/*    <ContentContainer className='horizontal'>*/}
                    {/*        <Text as='span' className="flexItem t-listText">Kirchensteuer</Text>*/}
                    {/*        <Text as='span'*/}
                    {/*              className="flexItem title4 t-right">{results?.cantonal?.churchTax?.tax && formatNonNull(results.cantonal.churchTax.tax, 'currency', "de-CH")}</Text>*/}
                    {/*    </ContentContainer>*/}
                    {/*</ObjectItem>*/}
                </ObjectList>
                {totalCantonSummary()}
            </Expander>
            <Expander title='Direkte Bundessteuer' headerTitleTag='h3' headerTitleTagClass='t-listTextBig'
                      headerTitleSize='l-fb-70'
                      headerContent={
                          <ContentContainer className='horizontal l-flex-justify-end'>
                              <Text as='span' className="flexItem t-listTextBig hiddenLabel">Total:</Text>
                              <Text as='span'
                                    className="flexItem t-listTextBig t-right">{results.federal && formatNonNull(results.federal.totalTax, 'currency', "de-CH")}</Text>
                          </ContentContainer>}
                      headerContentSize='l-fb-30 l-flex-justify-end'>
                {totalBundesSummary()}
            </Expander>
            <ObjectItem className='totalRow'>
                <ContentContainer className='horizontal'>
                    <Text as='span' className="flexItem title3">Total Steuer</Text>
                    {!loading && <Text as='span'
                                       className="flexItem title3 t-right">{formatNonNull(results.totalTax, 'currency', "de-CH")}</Text>}
                </ContentContainer>
            </ObjectItem>
        </ObjectList>
    )
}